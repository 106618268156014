<script>
import { defineComponent, useAttrs } from 'vue'

import KIcon from './KIcon.vue'
import KSpinner from './KSpinner.vue'
import { convertToClassString } from '@/utils/class'

export default defineComponent({
    name: 'KButton',
    components: {
        KIcon,
        KSpinner,
    },
    props: {
        // eslint-disable-next-line vue/require-default-prop, vue/no-reserved-props
        class: [String, Array, Object],
        type: {
            type: String,
            default: 'button',
        },
        iconLeft: {
            type: String,
            default: undefined,
        },
        iconRight: {
            type: String,
            default: undefined,
        },
        icon: {
            type: String,
            default: undefined,
        },
        mode: {
            type: String,
            default: 'primary',
        },
        danger: {
            type: Boolean,
            default: false,
        },
        href: {
            type: String,
            default: undefined,
        },
        to: {
            type: [String, Object],
            default: undefined,
        },
        size: {
            type: String,
            default: 'XL',
        },
        loading: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        lowercase: {
            type: Boolean,
            default: false,
        },
    },
    setup() {
        const attrs = useAttrs()

        return { attrs }
    },
    computed: {
        is_button() {
            return (this.type === 'button' || this.type === 'submit') && !this.is_link
        },
        is_primary() {
            return this.mode === 'primary'
        },
        is_secondary() {
            return this.mode === 'secondary'
        },
        is_link() {
            return this.mode === 'link'
        },
        is_text() {
            return this.mode === 'text'
        },
        is_icon() {
            return this.mode === 'icon'
        },
        is_blank() {
            return this.attrs?.target === '_blank'
        },

        class_list() {
            return {
                'transition-all border border-transparent relative inline-block': true,

                'text-black': this.is_secondary && !this.disabled,
                'text-link': this.is_link,
                'text-white': this.is_primary && !this.disabled,
                'text-secondary--text': this.disabled,
                'text-sm': this.size === 'S',

                'bg-brand hover:bg-brand--hover': this.is_primary && !this.disabled,
                'border-brand hover:border-brand--hover': this.is_primary && !this.disabled,
                'bg-white hover:bg-primary--background': this.is_secondary && !this.disabled,
                'bg-secondary--graphic': (this.is_secondary || this.is_primary) && this.disabled,

                'bg-red border-red hover:bg-red--hover hover:border-red--hover':
                    this.danger && !this.is_text && !this.is_link,

                'border-primary--border': this.is_secondary,
                'shadow-sm': this.is_primary || this.is_secondary,
                'rounded-md': this.is_primary || this.is_secondary || this.is_icon,

                'px-6 py-3': this.size === 'XL' && !this.is_icon && !this.is_link,
                'px-4 py-1.5 ': this.size === 'M' && !this.is_icon && !this.is_link,
                'px-2.5 py-1.5 ': this.size === 'S' && !this.is_icon && !this.is_link,

                'p-3': this.is_icon,

                'hover:text-link--hover':
                    (this.is_text || this.is_link || this.is_icon) && !this.disabled,
                'text-red hover:text-red--hover': this.danger && (this.is_text || this.is_link),

                'inline-block': this.is_text || this.is_link,
                'font-medium': !this.is_text && !this.is_link,
                'cursor-pointer': !this.disabled,
            }
        },
        class_list_string() {
            return convertToClassString([this.class_list, this.class])
        },
    },
})
</script>

<template>
    <Link
        v-if="href && !is_blank"
        :href="href"
        :class="class_list_string"
        :disabled="disabled || loading"
        :as="is_button ? 'button' : 'a'"
        v-bind="attrs">
        <div
            v-if="loading"
            class="absolute left-0 right-0 bottom-0 top-0 flex items-center justify-center transition">
            <KSpinner :color="is_primary ? '#fff' : 'var(--color-brand)'" class="-my-2" />
        </div>
        <div
            :class="{
                'opacity-0': loading,
                'justify-center': mode !== 'text' && !is_link,
            }"
            class="flex items-center">
            <KIcon v-if="iconLeft" :icon="iconLeft" class="mr-3" />
            <KIcon v-if="icon" :icon="icon" />
            <div :class="{ 'first-letter:capitalize': !lowercase }"><slot /></div>
            <KIcon v-if="iconRight" :icon="iconRight" class="ml-auto" />
        </div>
    </Link>
    <component
        :is="is_button ? 'button' : 'a'"
        v-else
        :href="href"
        :type="type === 'submit' || type === 'button' ? type : null"
        :class="class_list_string"
        :disabled="disabled || loading"
        v-bind="attrs">
        <div
            v-if="loading"
            class="absolute left-0 right-0 bottom-0 top-0 flex items-center justify-center transition">
            <KSpinner :color="is_primary ? '#fff' : 'var(--color-brand)'" class="-my-2" />
        </div>
        <div
            :class="{
                'opacity-0': loading,
                'justify-center': mode !== 'text' && !is_link,
            }"
            class="flex items-center">
            <KIcon v-if="iconLeft" :icon="iconLeft" class="mr-3" />
            <KIcon v-if="icon" :icon="icon" />
            <div :class="{ 'first-letter:capitalize': !lowercase }"><slot /></div>
            <KIcon v-if="iconRight" :icon="iconRight" class="ml-auto" />
        </div>
    </component>
</template>
