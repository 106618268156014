<script>
import KFormValidatorsMixin from '@/mixins/KFormValidatorsMixin.vue'
import api from '@/api'

export default {
    mixins: [KFormValidatorsMixin],

    data() {
        return {
            types: {
                suggestion: 'suggestion',
                bug: 'bug',
            },
            formData: {
                type: undefined,
                message: '',
                screenshot: undefined,
            },
            loading: false,
            success: false,
        }
    },
    computed: {
        ...mapGetters({ user: 'user', project: 'project' }),
    },
    methods: {
        async sendFeedback(feedbackData) {
            const formData = new FormData()
            const { user, project } = this

            formData.append('source', 'storefront')
            formData.append('type', feedbackData.type)
            formData.append('message', feedbackData.message)
            formData.append('project_id', project?.id)
            formData.append(
                'viewport[width]',
                Math.max(document.documentElement.clientWidth, window.innerWidth || 0).toString()
            )
            formData.append(
                'viewport[height]',
                Math.max(document.documentElement.clientHeight, window.innerHeight || 0).toString()
            )
            if (user?.id) {
                Object.keys(user).forEach(key =>
                    formData.append(`viewport[user][${key}]`, user[key])
                )
            } else {
                formData.append(`viewport[user][email]`, feedbackData.email)
            }
            if (feedbackData.screenshot) {
                formData.append('screenshot', feedbackData.screenshot)
            }

            return api.request({
                url: `${this.$root.base_url}/kustomer-api/feedback`,
                method: 'post',
                config: { headers: { 'Content-Type': 'multipart/form-data' } },
                data: formData,
            })
        },
        async onSubmit() {
            try {
                this.loading = true

                await this.sendFeedback(this.formData)

                this.success = true
            } catch (error) {
                this.$root.handleError({ ...error, form: this.$refs['form'] })
            } finally {
                this.loading = false
            }
        },
    },
}
</script>

<template>
    <TransitionGroup name="fade" enter-from-class="opacity-0" enter-active-class="transition">
        <div v-if="success">
            <div class="w-12 h-12 flex items-center justify-center rounded-full mx-auto bg-green">
                <KIcon icon="CheckIcon" class="text-white" />
            </div>
            <p class="text-center mt-3 mb-2">
                {{ $t('Thank you for your feedback!') }}
            </p>
            <p class="text-center text-sm text-secondary--text">
                {{ $t('We will review your message as soon as possible') }}
            </p>
        </div>
        <div v-else>
            <div class="w-12 h-12 flex items-center justify-center rounded-full mx-auto bg-brand">
                <KIcon
                    :icon="
                        formData.type === types.suggestion
                            ? 'LightBulbIcon'
                            : formData.type === types.bug
                            ? 'BugAntIcon'
                            : 'ChatBubbleOvalLeftEllipsisIcon'
                    "
                    class="text-white" />
            </div>
            <p class="text-center mt-3 mb-6">
                {{
                    $t(
                        formData.type === types.suggestion
                            ? 'What is your suggestion?'
                            : formData.type === types.bug
                            ? 'Please explain what happened'
                            : 'Help us improve our service'
                    )
                }}
            </p>
        </div>
        <div v-if="!success && formData.type">
            <KForm ref="form" @submit="onSubmit">
                <KTextField
                    v-if="!user"
                    v-model="formData.email"
                    :placeholder="$t('email')"
                    :rules="
                        combineFieldValidators([
                            fieldValidators.required(),
                            fieldValidators.email(),
                        ])
                    "
                    :field-attrs="{ autocomplete: 'email', type: 'email' }"
                    name="email"
                    class="mb-6" />
                <KTextField
                    v-model="formData.message"
                    :placeholder="$c($t('write your message here'))"
                    :rules="fieldValidators.required()"
                    :disabled="loading"
                    :rows="3"
                    class="mb-6"
                    name="message"
                    textarea />
                <KPictureField
                    v-model="formData.screenshot"
                    :button-text="$t('upload a screenshot')"
                    :disabled="loading"
                    :rules="combineFieldValidators([fieldValidators.imageSizeMb()])"
                    name="screenshot"
                    class="mb-6" />
                <KButton :loading="loading" class="w-full" type="submit">
                    {{ $t('submit') }}
                </KButton>
            </KForm>
        </div>
        <div v-else-if="!success">
            <KButton
                icon-left="LightBulbIcon"
                class="mb-3 w-full"
                mode="secondary"
                @click="formData.type = types.suggestion">
                {{ $t('I have a suggestion') }}
            </KButton>
            <!-- <KButton
                @click="formData.type = types.bug"
                iconLeft="BugAntIcon"
                mode="secondary"
                class="w-full"
            >
                {{ $t('I found a bug') }}
            </KButton> -->

            <div class="text-center w-full mt-4">
                <KButton
                    href="https://help.kinchaku.com/category/226-category"
                    mode="text"
                    class="font-medium text-sm whitespace-nowrap"
                    size="M"
                    target="_blank">
                    {{ $t('manual') }}
                </KButton>
            </div>
        </div>
    </TransitionGroup>
</template>
