export const convertHeicToPng = async blobfile => {
    const heic2any = (await import('heic2any')).default

    const blobRes = await fetch(URL.createObjectURL(blobfile))
    const blob = await blobRes.blob()
    // convert to PNG - response is blob
    const resultBlob = await heic2any({ blob })
    return new File([resultBlob], 'heic' + '.png', {
        type: 'image/png',
        lastModified: new Date().getTime(),
    })
}

export const findFace = async bitmap => {
    let facefinder_classify_region = () => -1.0
    let cascadeLoaded = false

    const { pico } = await import('./pico.js')

    if (cascadeLoaded) return startDetection(bitmap)

    const response = await fetch('/js/facefinder')
    const buffer = await response.arrayBuffer()
    const bytes = new Int8Array(buffer)

    facefinder_classify_region = pico.unpack_cascade(bytes)

    cascadeLoaded = true

    return startDetection(bitmap)

    async function startDetection(bitmap) {
        const { pico } = await import('./pico.js')
        const MAX_WIDTH = 1280
        const MAX_HEIGHT = 800
        const canv = document.createElement('canvas')
        const ctx = canv.getContext('2d')

        if (!ctx) return false

        let width = bitmap.width
        let height = bitmap.height
        // resize large photos
        if (width > height) {
            if (width > MAX_WIDTH) {
                height = height * (MAX_WIDTH / width)
                width = MAX_WIDTH
            }
        } else {
            if (height > MAX_HEIGHT) {
                width = width * (MAX_HEIGHT / height)
                height = MAX_HEIGHT
            }
        }
        const scale = width / bitmap.width
        ctx.scale(scale, scale)
        canv.width = width
        canv.height = height
        ctx.drawImage(bitmap, 0, 0, width, height)
        const rgba = ctx.getImageData(0, 0, width, height).data
        const image = {
            pixels: rgba_to_grayscale(rgba, height, width),
            nrows: height,
            ncols: width,
            ldim: width,
        }
        const params = {
            shiftfactor: 0.1, // move the detection window by 10% of its size
            minsize: 200, // 20, minimum size of a face
            maxsize: 1000, // maximum size of a face
            scalefactor: 1.1, // for multiscale processing: resize the detection window by 10% when moving to the higher scale
        }
        // dets is an array that contains (r, c, s, q) quadruplets
        // (representing row, column, scale and detection score)
        let dets = pico.run_cascade(image, facefinder_classify_region, params)
        // cluster the obtained detections
        dets = pico.cluster_detections(dets, 0.2) // set IoU threshold to 0.2
        const qthresh = 5.0 // this constant is empirical: other cascades might require a different one
        let faces = 0
        for (let i = 0; i < dets.length; ++i)
            // check the detection score
            // if it's above the threshold, draw it
            if (dets[i][3] > qthresh) {
                // eslint-disable-next-line
                // console.log(dets[i])
                faces++
                // ctx.beginPath()
                // ctx.arc(dets[i][1], dets[i][0], dets[i][2] / 2, 0, 2 * Math.PI, false)
                // ctx.lineWidth = 3
                // ctx.strokeStyle = i == 0 ? 'red' : 'green'
                // ctx.stroke()
            }
        return faces === 1
    }
    //a function to transform an RGBA image to grayscale
    function rgba_to_grayscale(rgba, nrows, ncols) {
        const gray = new Uint8Array(nrows * ncols)
        for (let r = 0; r < nrows; ++r)
            for (let c = 0; c < ncols; ++c)
                // gray = 0.2*red + 0.7*green + 0.1*blue
                gray[r * ncols + c] =
                    (2 * rgba[r * 4 * ncols + 4 * c + 0] +
                        7 * rgba[r * 4 * ncols + 4 * c + 1] +
                        1 * rgba[r * 4 * ncols + 4 * c + 2]) /
                    10
        return gray
    }
}

export default {
    convertHeicToPng,
    findFace,
}
