<script>
import { defineComponent } from 'vue'
import { ErrorMessage } from 'vee-validate'

export default defineComponent({
    name: 'FieldLayout',
    components: { ErrorMessage },
    props: {
        name: { type: String, required: true },
        isRequired: { type: Boolean, default: false },
        label: { type: String, default: '' },
        showError: { type: Boolean, default: true },
    },
})
</script>

<template>
    <div>
        <label
            v-if="label || $slots['labelSuffix']"
            :for="name"
            class="flex items-center justify-between">
            <div v-if="label" class="font-medium mb-1 first-letter:capitalize">
                {{ label }}
                <span v-if="isRequired" class="text-brand">*</span>
            </div>
            <slot name="labelSuffix" />
        </label>
        <slot />
        <Transition
            v-if="showError"
            enter-from-class="opacity-0"
            enter-active-class="transition duration-300">
            <ErrorMessage :name="name" class="text-sm text-red mt-1" />
        </Transition>
    </div>
</template>
