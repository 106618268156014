<script>
import { defineComponent } from 'vue'
import { Cropper as VueCropper } from 'vue-advanced-cropper'
import { useField } from 'vee-validate'
import 'vue-advanced-cropper/dist/style.css'

import FieldMixin from './mixins/FieldMixin.vue'
import KButton from '../KButton.vue'
import { convertHeicToPng, findFace } from '@/utils/image'

export const ALLOWED_MODES = ['image', 'face']

export default defineComponent({
    name: 'KPictureField',
    components: { VueCropper, KButton },
    mixins: [FieldMixin],
    props: {
        name: { type: String, default: undefined },
        modelValue: { type: [Object, File, Blob], default: undefined },
        label: { type: String, default: undefined },
        buttonText: { type: String, default: undefined },
        mode: {
            type: String,
            validator: function (value) {
                return ALLOWED_MODES.includes(value)
            },
            default: 'image',
            message: `The mode should be one of ${ALLOWED_MODES.join(',')}`,
        },
    },
    emits: ['update:modelValue', 'update:faceDetected', 'error'],
    setup(props) {
        const { meta, errorMessage, handleChange, handleBlur, value } = useField(
            props.name,
            props.rules,
            { initialValue: props.modelValue }
        )

        return {
            meta,
            errorMessage,
            handleChange,
            handleBlur,
            value,
        }
    },
    data() {
        return {
            preview: undefined,
            faceDetected: false,
            canvasCfg: {
                minHeight: 150,
                minWidth: 150,
                maxHeight: 1280,
                maxWidth: 1280,
            },
        }
    },
    computed: {
        detect_face() {
            return this.mode === 'face'
        },
        src() {
            return this.preview || this.modelValue
        },
    },
    watch: {
        value() {
            this.onChangeValue()
        },
        faceDetected() {
            this.$emit('update:faceDetected', this.faceDetected)
        },
    },
    methods: {
        onPickFile() {
            const input = this.$refs['input']

            input.click()
        },
        onCropperChange({ canvas }) {
            this.$emit('error', undefined)
            canvas.toBlob(this.setFile)
        },
        async onChangeValue() {
            let file = Array.isArray(this.value) ? this.value[0] : this.value

            if (!file) {
                this.preview = undefined
                return this.$emit('update:modelValue', undefined)
            }
            if (!(file instanceof File)) return

            this.preview = undefined

            const reader = new FileReader()
            const fileNameExt = file.name.substr(file.name.lastIndexOf('.') + 1)

            if (fileNameExt.toLowerCase() === 'heic') file = await convertHeicToPng(file)

            reader.onload = e => (this.preview = e.target?.result)
            reader.readAsDataURL(file)

            this.setFile(file)
        },
        async setFile(file) {
            if (!file) return

            this.faceDetected = false

            if (this.detect_face) {
                await createImageBitmap(file)
                    .then(bitmap => findFace(bitmap))
                    .then(result => (this.faceDetected = result))
            }

            this.handleChange(file)
            this.$emit('update:modelValue', file)
        },
    },
})
</script>

<template>
    <div class="form-group mt-2" :class="{ 'has-error': !meta.valid && meta.validated }">
        <input
            id="picture"
            ref="input"
            type="file"
            class="hidden"
            accept="image/png, image/jpeg, image/jpg, image/heic"
            :disabled="disabled"
            :name="name"
            @input="handleChange"
            @blur="handleBlur" />

        <label v-if="label" class="form-label" for="picture">
            {{ label }}
            <span v-if="is_required" class="text-brand">*</span>
        </label>
        <div>
            <div class="relative">
                <template v-if="detect_face && preview">
                    <!--                <h4 class="text-center text-sm mb-2">-->
                    <!--                    {{ $t('Drag and scale frame to crop the image' ) }}-->
                    <!--                </h4>-->
                    <VueCropper
                        :stencil-props="{ aspectRatio: 1 }"
                        :canvas="canvasCfg"
                        :src="preview"
                        class="mt-2 block w-full rounded-md overflow-hidden shadow image-preview"
                        @change="onCropperChange" />
                </template>
                <img
                    v-else-if="src"
                    :src="src"
                    class="mt-2 rounded-md block w-full shadow image-preview" />
                <div
                    v-if="!!value && detect_face"
                    :class="{ checkmark: meta.valid && faceDetected }"
                    class="absolute top-4 left-8" />
            </div>
            <!--            <img v-if="src && !detect_face" :src="src" class="mt-2 w-48 h-48 shadow" />-->
            <div
                class="flex flex-wrap w-full items-center mt-2 justify-center"
                :class="{ 'justify-between': !!meta.valid }">
                <KButton
                    class="w-full"
                    mode="secondary"
                    type="button"
                    :disabled="disabled"
                    @click.prevent="onPickFile">
                    {{ buttonText || $t('Upload image') }}
                </KButton>
            </div>
        </div>
        <div v-if="errorMessage" class="text-sm text-red mt-1">{{ errorMessage }}</div>
    </div>
</template>

<style scoped>
.checkmark {
    display: inline-block;
    transform: rotate(45deg);
    height: 25px;
    width: 12px;
    border-bottom: 7px solid #78b13f;
    border-right: 7px solid #78b13f;
    margin-right: 10px;
}
.image-preview {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}
</style>
