<script setup lang="ts">
import { useProject } from '@/composables/use-project'

const project = useProject().value as App.Data.ProjectData
const { link_to_terms, link_to_privacy, link_to_cancelation } = project
</script>

<template>
    <KDropDown v-if="link_to_terms || link_to_privacy || link_to_cancelation">
        <template #trigger="{ click }">
            <KButton
                icon="EllipsisHorizontalCircleIcon"
                mode="icon"
                class="-my-2 -mx-3"
                @click="click" />
        </template>
        <template #default="{ close }">
            <div class="py-2">
                <KButton
                    v-if="link_to_terms"
                    :href="link_to_terms"
                    mode="text"
                    class="whitespace-nowrap text-sm text-secondary--text w-full py-2 px-4"
                    target="_blank"
                    type="a"
                    @click="close">
                    {{ $t('terms and conditions') }}
                </KButton>
                <KButton
                    v-if="link_to_privacy"
                    :href="link_to_privacy"
                    mode="text"
                    type="a"
                    class="whitespace-nowrap text-sm text-secondary--text w-full py-2 px-4"
                    target="_blank"
                    @click="close">
                    {{ $t('privacy policy') }}
                </KButton>
                <KButton
                    v-if="link_to_cancelation"
                    :href="link_to_cancelation"
                    mode="text"
                    type="a"
                    class="whitespace-nowrap text-sm text-secondary--text w-full py-2 px-4"
                    target="_blank"
                    @click="close">
                    {{ $t('cancelation policy') }}
                </KButton>
            </div>
        </template>
    </KDropDown>
</template>
