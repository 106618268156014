<script setup lang="ts">
const props = defineProps<{
    user: App.Data.Shared.UserData
    email?: string | null
    avatar?: string | null
}>()

const user_email = computed(() => props.email || props.user?.email)
const contact_image = computed(() => {
    const { user } = props
    if (!user?.contact?.contact_image) return false

    return (
        import.meta.env.VITE_BACKEND_URI +
        `/assets/contact/v2/${user.contact.shortuuid}/image?lastmod=${Date.now()}`
    )
})
const user_image = computed(() => props.avatar || contact_image.value)
</script>

<template>
    <div v-if="user_email" class="flex items-center">
        <KAvatar v-if="user_image" :src="user_image" class="mr-3" />
        <div>
            <div class="text-secondary--text text-xs capitalize">{{ $t('email') }}</div>
            <div class="text-sm truncate">{{ user_email }}</div>
        </div>
    </div>
</template>
