<script>
import { defineComponent } from 'vue'

import KButton from './KButton.vue'
import { convertToClassString } from '@/utils/class'

export default defineComponent({
    name: 'KModal',
    components: { KButton },
    props: {
        modelValue: { type: Boolean, default: false },
        class: [String, Array, Object],
        showBack: Boolean,
        showClose: { type: Boolean, default: true },
        mdRight: Boolean,
        xsFull: Boolean,
        insideFull: Boolean,
    },
    emits: ['update:modelValue', 'close'],
    data() {
        return {
            isOpen: false,
        }
    },
    computed: {
        class_list_string() {
            return convertToClassString([
                {
                    'bg-white w-full m-auto relative rounded-lg': true,
                    'p-4 md:p-6': !this.insideFull,

                    'rounded-none h-full w-full md:h-auto md:p-4 md:rounded-lg': this.xsFull,
                    'md:mr-0 md:w-[360px] md:ml-auto md:h-full md:left-auto md:right-0 md:transform-none md:top-0 md:bottom-0 md:rounded-none md:overflow-y-auto':
                        this.mdRight,
                },
                this.class,
            ])
        },
    },
    watch: {
        modelValue: {
            handler(value) {
                this.isOpen = value
            },
            immediate: true,
        },
        isOpen() {
            if (typeof document === 'undefined') return
            if (this.isOpen) {
                document.body.className = `${document.body.className || ''} overflow-hidden`
            } else {
                document.body.className = (document.body.className || '').replace(
                    /overflow-hidden/g,
                    ''
                )
            }
        },
    },
    mounted() {
        if (typeof document === 'undefined') return
        document.addEventListener('keydown', this.checkKeys)
    },
    unmounted() {
        if (typeof document === 'undefined') return
        document.removeEventListener('keydown', this.checkKeys)
        document.body.className = (document.body.className || '').replace(/overflow-hidden/g, '')
    },
    methods: {
        open() {
            this.isOpen = true
            this.$nextTick(() => this.$emit('update:modelValue', true))
        },
        close() {
            this.isOpen = false
            this.$nextTick(() => {
                this.$emit('close')
                this.$emit('update:modelValue', false)
            })
        },
        checkKeys(e) {
            if (this.showClose && e.keyCode == 27) this.close()
        },
    },
})
</script>

<template>
    <div>
        <slot name="trigger" :open="open" :close="close"></slot>
        <Teleport to="body">
            <Transition enter-from-class="opacity-0" enter-active-class="transition duration-300">
                <div
                    v-if="isOpen"
                    class="fixed overflow-y-auto top-0 left-0 right-0 bottom-0 z-10 flex"
                    :class="{ 'p-2': !xsFull, 'md:p-0': mdRight }">
                    <div
                        class="fixed top-0 left-0 right-0 bottom-0 bg-black bg-opacity-50 -z-10"
                        :class="{ 'hidden md:flex': xsFull }"
                        @click="() => showClose && close()" />
                    <div :class="class_list_string">
                        <KButton
                            v-if="showClose"
                            class="absolute top-0 right-0 md:top-2 md:right-2 text-secondary--graphic z-10 mt-0.5"
                            mode="icon"
                            icon="XMarkIcon"
                            @click="close" />
                        <slot :open="open" :close="close"></slot>
                    </div>
                </div>
            </Transition>
        </Teleport>
    </div>
</template>
