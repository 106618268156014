<script>
import { defineComponent } from 'vue'
import { useField } from 'vee-validate'

import FieldMixin from './mixins/FieldMixin.vue'

export default defineComponent({
    name: 'KCheckField',
    mixins: [FieldMixin],
    props: {
        modelValue: {
            type: [Boolean, String],
            default: undefined,
        },
        size: [Number, String],
    },
    emits: ['update:modelValue'],
    setup(props) {
        const { errorMessage, handleChange, handleBlur, value } = useField(
            props.name,
            props.rules,
            { initialValue: props.modelValue }
        )

        return { errorMessage, handleChange, handleBlur, value }
    },
    watch: {
        value() {
            this.$emit('update:modelValue', Boolean(this.value))
        },
    },
    methods: {
        onChange() {
            this.handleChange(!this.value)
        },
    },
})
</script>

<template>
    <div>
        <div class="flex">
            <input
                v-bind="fieldAttrs"
                :disabled="disabled"
                :checked="value"
                :style="size ? { width: `${size}px`, height: `${size}px` } : undefined"
                class="form-group mr-2 mt-0.5"
                type="checkbox"
                @change="onChange"
                @blur="handleBlur" />
            <label class="flex items-center justify-between" :for="name">
                <span class="text-sm">
                    {{ label }}
                    <span v-if="is_required" class="text-brand">*</span>
                </span>
            </label>
        </div>
        <div v-if="errorMessage" class="text-sm text-red mt-1">{{ errorMessage }}</div>
    </div>
</template>
