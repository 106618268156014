<script>
import { defineComponent } from 'vue'
import { Field } from 'vee-validate'

import FieldMixin from './mixins/FieldMixin.vue'
import FieldLayout from './layouts/FieldLayout.vue'

export default defineComponent({
    name: 'KTextField',
    components: { FieldLayout, Field },
    mixins: [FieldMixin],
    props: {
        textarea: Boolean,
        rows: { type: Number, default: 1 },
    },
    computed: {
        class_list() {
            return {
                'bg-white': !this.disabled,
                'bg-primary--background text-secondary--text shadow-none': this.disabled,
                'h-12': !this.textarea,
            }
        },
        tag() {
            if (this.textarea) return 'textarea'

            return 'input'
        },
    },
})
</script>

<template>
    <Field v-slot="{ field, errorMessage }" :name="name" :rules="rules">
        <FieldLayout v-bind="$attrs" :name="name" :label="label" :is-required="is_required">
            <component
                v-bind="{ ...field, ...fieldAttrs }"
                :is="tag"
                :class="{ ...class_list, 'border-red': errorMessage }"
                :disabled="disabled"
                :placeholder="placeholder"
                :rows="textarea ? rows : undefined"
                :style="{ 'min-height': textarea ? `${32 + 16 * rows}px` : 'auto' }"
                class="min-h-full px-3 shadow border border-primary--border rounded-md w-full transition" />
            <template #labelSuffix>
                <slot name="labelSuffix" />
            </template>
        </FieldLayout>
    </Field>
</template>
