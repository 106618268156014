<script setup lang="ts">
import * as Sentry from '@sentry/vue'
import { colord } from 'colord'
import { useProject } from '@/composables/use-project'
import { useFlags } from '@/composables/use-flags'
import { useWorkspace } from '@/composables/use-workspace'
import { useAuth } from '@/composables/use-auth'
import { useRoot } from '@/composables/use-root'

import MainMenu from '@/partials/menu/MainMenu.vue'
import MainMenuModal from '@/partials/menu/MainMenuModal.vue'

// import PageNotFound from '@/components/errors/PageNotFound.vue'
// import ServiceUnavailable from '@/components/errors/ServiceUnavailable.vue'
import ProjectTermsAndPrivacyDropdown from '@/partials/project/ProjectTermsAndPrivacyDropdown.vue'

const props = defineProps({
    title: { type: String, default: '' },
    back: { type: String, required: false, default: '' },
    loading: Boolean,
    maxWidthContainer: Boolean,
    withoutGoBack: Boolean,
})

const project = useProject().value as App.Data.ProjectData | null
const flags = useFlags().value as App.Data.Shared.FlagsData
const workspace = useWorkspace().value as App.Data.Shared.WorkspaceData
const user = useAuth() as App.Data.Shared.UserData | null
const $root = useRoot().value

const mainMenuShown = ref(false)

// const is_full_404_error = computed(() => store.getters.is_full_404_error)
// const is_full_503_error = computed(() => store.getters.is_full_503_error)
const is_whitelabel_mode = computed(() => !!flags.with_whitelabel)
const have_project = computed(() => !!project)
const project_brand_name = computed(() => project?.brand_name)
const back_link = computed(() =>
    props.back ? props.back : project ? project.link : route('sf.home')
)

const branding_colors = computed(() => {
    if (!workspace?.colors) return ''

    const primary = workspace.colors.primary || '#000000'
    const active_pass = workspace.colors.active_pass || '#000000'
    const text_links = workspace.colors.text_links || '#000000'

    return `
        --color-brand: ${primary};
        --color-brand--hover: ${colord(primary).saturate(0.18).darken(0.1).toHex()};
        --color-primary--background: ${colord(primary).desaturate(0.45).lighten(0.5).toHex()};
        --color-primary--text: ${colord(primary).desaturate(0.1).darken(0.77).toHex()};
        --color-primary--graphic: ${colord(primary).desaturate(0.2).darken(0.3).toHex()};
        --color-primary--line: ${colord(primary).desaturate(0.33).lighten(0.44).toHex()};
        --color-primary--border: ${colord(primary).desaturate(0.38).lighten(0.34).toHex()};
        --color-secondary--background: ${colord(primary).desaturate(0.4).darken(0.04).toHex()};
        --color-secondary--text: ${colord(primary).desaturate(0.38).darken(0.1).toHex()};
        --color-secondary--graphic: ${colord(primary).desaturate(0.32).lighten(0.46).toHex()};
        --color-indigo-light--background: ${colord(primary).lighten(0.49).toHex()};
        --color-indigo-light--hover: ${colord(primary).lighten(0.44).toHex()};
        --color-pass--active: ${colord(active_pass).toHex()};
        --color-link: ${colord(text_links).toHex()};
        --color-link--hover: ${colord(text_links).saturate(0.18).darken(0.1).toHex()};
    `
})

watch(
    branding_colors,
    value => {
        if (typeof document === 'undefined') return
        if (value) document.documentElement.style.cssText = value
    },
    { immediate: true }
)

onMounted(() => {
    if (user && typeof window !== 'undefined' && window._paq) {
        window._paq.push(['setUserId', user.id])
    }
    if (import.meta.env.VITE_SENTRY_DSN) {
        Sentry.setUser(user ? { email: user.email, id: user.id, language: user.language } : null)
    }
})
</script>

<template>
    <Head :title="title" />
    <div
        class="absolute top-0 right-0 bottom-0 left-0 h-full flex flex-col bg-primary--background overflow-y-auto">
        <div class="print:hidden border-primary--border bg-white">
            <div
                class="flex items-center justify-between max-w-[1800px] mx-auto px-4 h-14 md:h-16 md:px-16 relative border-b border-primary--border">
                <div>
                    <div v-if="is_whitelabel_mode" class="flex items-center">
                        <img :src="workspace.logo" class="w-10 h-10 block" />
                        <div class="font-bold ml-3">{{ workspace.brand_name }}</div>
                    </div>
                    <img v-if="!is_whitelabel_mode" src="/images/kinchaku_logo.svg" class="block" />
                </div>

                <div class="-m-4">
                    <MainMenuModal v-if="$root.isMobile" v-model="mainMenuShown">
                        <template #trigger="{ open }">
                            <KButton
                                class="text-primary--graphic md:hidden border-0"
                                icon="Bars3Icon"
                                mode="icon"
                                @click="open" />
                        </template>
                    </MainMenuModal>
                    <KDropDown v-if="user">
                        <template #trigger="{ click, show }">
                            <KButton
                                :class="{ 'text-link': show }"
                                class="capitalize hidden md:flex"
                                icon-left="UserCircleIcon"
                                mode="text"
                                @click="click">
                                {{ $t('my account') }}
                            </KButton>
                        </template>
                        <div class="w-80 hidden md:block">
                            <MainMenu />
                        </div>
                    </KDropDown>
                    <KButton
                        v-else
                        :href="have_project ? `${project.link}/signin` : '/signin'"
                        class="hidden md:flex"
                        icon-left="ArrowLeftOnRectangleIcon"
                        mode="text">
                        {{ $t('sign in') }}
                    </KButton>
                </div>
            </div>
            <div
                v-if="
                    have_project &&
                    (!is_whitelabel_mode ||
                        (project_brand_name && project_brand_name !== workspace.brand_name))
                "
                class="md:border-b bg-primary--background md:bg-indigo-light--background border-primary--border">
                <div
                    class="flex items-center justify-between max-w-[1800px] mx-auto px-4 h-14 md:h-16 md:px-16">
                    <Link :href="project.link" class="flex items-center cursor-pointer">
                        <KAvatar
                            v-if="workspace.logo && !is_whitelabel_mode"
                            :src="workspace.logo"
                            class="mr-3" />
                        <div class="font-bold">
                            {{ is_whitelabel_mode ? project_brand_name : workspace.brand_name }}
                        </div>
                    </Link>
                    <div>
                        <ProjectTermsAndPrivacyDropdown v-if="!is_whitelabel_mode" />
                    </div>
                </div>
            </div>
        </div>

        <div class="flex-1 flex flex-col relative">
            <div
                v-if="loading"
                class="flex-1 flex items-center justify-center bg-indigo-light--background">
                <KSpinner :size="64" />
            </div>
            <div
                v-else
                class="flex-1 flex flex-col max-w-[1800px] w-full mx-auto relative px-4 pt-4 pb-4 md:pt-4 md:px-16">
                <KButton
                    v-if="!withoutGoBack"
                    class="md:top-4 md:left-10 md:absolute -mr-4 mb-4 -ml-6 md:ml-0 print:hidden"
                    icon-left="ArrowSmallLeftIcon"
                    mode="text"
                    :href="back_link">
                    {{ $t('go back') }}
                </KButton>
                <div
                    class="flex flex-col flex-1"
                    :class="{
                        'md:grid md:grid-cols-3': !!$slots['second'],
                        'w-full mx-auto': !$slots['second'],
                        'max-w-4xl': !$slots['second'] && maxWidthContainer,
                    }">
                    <div
                        class="flex-1 md:my-6 flex flex-col"
                        :class="{
                            'md:mr-16 md:col-span-2': !!$slots['second'],
                        }">
                        <slot />
                    </div>
                    <div
                        v-if="!!$slots['second']"
                        class="-mx-4 -mb-4 sticky bottom-0 bg-primary--background md:mx-0 md:static md:bg-indigo-light--background md:-my-4 md:-mr-16 md:py-10 md:px-16 md:border-l border-primary--border">
                        <KDivider class="md:hidden" />
                        <slot name="second" />
                    </div>
                </div>
            </div>
        </div>
        <Footer class="print:hidden" />
        <CookiesConsentModal />
    </div>
</template>
